<template>
    <div>
        <div class="mask" @click="closeWindow" v-if="isShowStoreList"></div>
        <div class="store-container" :class="isShowStoreList?'transitionTop':'transitionDown'">
            <p class="store-title">请选择门店</p>
            <ul class="store-list">
                <li v-for="(item,index) in boss.storeList" :key="index" class="store-content" @click="chooseShopItem(item)">
                    <h4 class="store-name">{{item.shop_name}}</h4>
                    <p class="store-address" v-if="!!item.storeAds">地址：{{item.address_detail}}</p>
                    <van-icon name="checked" v-if="userStatus.curs_node_id_2==item.node_id" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
	import {mapState,mapMutations} from 'vuex'
  import table from '@/api/table'
	import util from '@/utils/util'
  import { Dialog } from 'vant';
	export default {
		name:"pick-store",
        props:{
			isShowStoreList:{
				type:Boolean,
                default:false
            }
        },
        data(){
		    return{
            }
        },
		computed:{
			...mapState(['boss','userStatus','openid']),
		},
    methods:{
      ...mapMutations(['updateBossPower','updateCursNodeId2','updateRenderBarList']),
			closeWindow(){
			    this.$emit("closeWindow")
      },
			/**
			 * 切换门店
			 */
			chooseShopItem(item){
        // document.title='123123'
        // console.log('123')
        table.changeNodeId({node_id:item.node_id}).then(response=>{
            if(response.data.result){
                this.updateBossPower(response.data.menu)
                var renderBarList=util.navAuthority(this.boss.navTabBar,this.userStatus.boss_power)
                if(renderBarList.length>0){
                  localStorage.setItem('shopNameTitle',item.shop_name)
                  this.updateCursNodeId2(item.node_id)
                  this.updateRenderBarList(renderBarList)
                  // 修改title门店名称
                  let title = item.shop_name+' - '+renderBarList[0].name
                  this.setDocumentTitle(title)
                  this.$router.push({
                  	name:renderBarList[0].link
                  })
                  this.$emit("choosePickItem")
                }else{
                  Dialog.alert({
                    title: '提示',
                    message: '无权限,请联系管理员',
                  }).then(() => {
                    // location.href=location.protocol+'//'+location.host+'/clairvoyance/'+'?openid='+this.openid
                    location.href=location.protocol+'//'+location.host+'/'+'?openid='+this.openid
                  })
                }

            }
        })

			},
      setDocumentTitle(title) {
        document.title = title;
        if (/ip(hone|od|ad)/i.test(navigator.userAgent)) {
          var i = document.createElement('iframe');
           i.src = '/favicon.ico'; i.style.display = 'none';
           i.onload = function() { setTimeout(function(){ i.remove(); }, 9) }
           document.body.appendChild(i);
        }
      }
    }
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";
    /* 遮罩层 */
    .mask{
        position:absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
        background:rgba(0, 0, 0, 0.3);
    }
    .store-container{
        position:fixed;
        left:0;
        bottom:0px;
        width:100%;
        background:#f2f2f2;
        z-index:6;
        transition:all 0.4s;
        .store-title{
            font-size:30px;
            line-height:1;
            padding:40px 22px;
            font-weight:bold;
        }
    }
    .transitionTop{
        bottom:0;
    }
    .transitionDown{
        bottom:-920px;
    }
    .store-list{
        max-height:700px;
        overflow:auto;
        -webkit-overflow-scrolling:touch;
        overflow-scrolling:touch;
    }
    .store-content{
        position:relative;
        margin:0 22px 22px;
        padding:28px;
        background:#fff;
        border-radius:10px;
        line-height:1.5;
        .store-name{
            font-size:30px;
            color:#333;
        }
        .store-address{
            font-size:24px;
            color:#999;
        }
        .van-icon-checked{
            position:absolute;
            top:28px;
            right:28px;
            font-size:40px;
            color:#aed581;
        }
    }
</style>
